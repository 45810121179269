import { TCallStatus, TCallStatusRu } from 'api/calls/types'
import {
  CALL_COMPLETE_STATUS_COLOR,
  CALL_CREATED_STATUS_COLOR,
  CALL_CANCELLED_STATUS_COLOR,
  CALL_SENT_STATUS_COLOR,
  INSPECTION_PLANNING_STATUS_COLOR,
  INSPECTION_PLANNED_STATUS_COLOR,
  INSPECTION_REJECTED_STATUS_COLOR,
  INSPECTION_COMPLETED_STATUS_COLOR,
} from 'utils/constants'
import { LegendColors } from './global'

export const callStatuses: TCallStatus[] = ['CREATED', 'SENT', 'CANCELLED', 'COMPLETE']

export const callStatusRuByEn: Record<TCallStatus, TCallStatusRu> = {
  CREATED: 'Создан',
  SENT: 'Направлен',
  CANCELLED: 'Отменен',
  COMPLETE: 'Завершен',
}

export const callStatusColorByEn: Record<TCallStatus, LegendColors> = {
  CREATED: CALL_CREATED_STATUS_COLOR,
  SENT: CALL_SENT_STATUS_COLOR,
  CANCELLED: CALL_CANCELLED_STATUS_COLOR,
  COMPLETE: CALL_COMPLETE_STATUS_COLOR,
}

export type TInspectionStatus = 'PLANNING' | 'PLANNED' | 'REJECTED' | 'COMPLETED'
export type TInspectionStatusRu = 'К планированию' | 'Запланирован' | 'Отклонен' | 'Выполнен'
export const inspectionStatuses: TInspectionStatus[] = ['PLANNING', 'PLANNED', 'REJECTED', 'COMPLETED']

export const inspectionStatusRuByEn: Record<TInspectionStatus, TInspectionStatusRu> = {
  PLANNING: 'К планированию',
  PLANNED: 'Запланирован',
  REJECTED: 'Отклонен',
  COMPLETED: 'Выполнен',
}

export const inspectionStatusColorByEn: Record<TInspectionStatus, LegendColors> = {
  PLANNING: INSPECTION_PLANNING_STATUS_COLOR,
  PLANNED: INSPECTION_PLANNED_STATUS_COLOR,
  REJECTED: INSPECTION_REJECTED_STATUS_COLOR,
  COMPLETED: INSPECTION_COMPLETED_STATUS_COLOR,
}

export const getCallStatusesForMenu = (selectedStatus: TCallStatus, isContractor: boolean) =>
  callStatuses.filter((status) => status !== selectedStatus && (isContractor ? status !== 'COMPLETE' : true))
