import { DrawerTopBar } from '../../../Administration/References/ReferenceContent/ReferenceDrawers/EditAndAddDrawer/EditAndAddDrawer.styles'
import { AuditDrawerContentWrapper, AuditDrawerWrapper, IconWrapper, StyledTooltip } from './AuditDrawer.styles'
import {
  AuditDrawerRemarkType,
  AuditDrawerProps,
  AuditDrawerVariant,
  remarkSecondaryStatusChangesNotifyInfo,
  initialAuditDrawerRemarkTypeIsSecondary,
  initialAuditDrawerRemarkType,
} from './AuditDrawer.types'
import { AuditItem } from './components/AuditItem'
import { AuditPrescriptionItem } from './components/AuditPrescriptionItem'
import { AuditRemarkItem } from './components/AuditRemarkItem'
import { AuditRemarkSecondaryItem } from './components/AuditRemarkSecondaryItem'
import { InfoOutlined as InfoIcon } from '@mui/icons-material'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { Box, Button, Drawer, Typography } from '@mui/material'
import { useGetCallAuditQuery } from 'api/calls'
import { CallAudit, CallAuditAction, callStatusesBackend, callStatusesData } from 'api/calls/calls.types'
import { useGetDocumentAuditQuery } from 'api/documents'
import { DocumentAudit, DocumentAuditAction } from 'api/documents/types'
import { useGetKsgAuditQuery } from 'api/ksg'
import { WorkAudit, WorkAuditAction } from 'api/ksg/types'
import { useGetPrescriptionAuditQuery } from 'api/prescriptions'
import { PrescriptionAudit, PrescriptionAuditAction } from 'api/prescriptions/types'
import { useGetRemarkAuditQuery, useGetRemarkAuditSecondaryQuery } from 'api/remarks'
import {
  RemarkAudit,
  RemarkAuditAction,
  RemarkSecondaryAudit,
  RemarkStatuses,
  remarkStatusesClient,
} from 'api/remarks/types'
import { EditCircleIcon } from 'assets/icons/EditCircleIcon'
import { Divider } from 'components/Divider'
import { Progress } from 'components/Progress'
import { ToggleButtonGroup } from 'components/ToggleButtonGroup'
import { WorkStatuses, workStatusesClient } from 'core/types/ksg'
import { PrescriptionStatus, prescriptionStatusRuByEn } from 'core/types/prescription'
import { useInfiniteScroll } from 'hooks/useInfiniteScroll'
import { useWindowSize } from 'hooks/useWindowSize'
import React from 'react'
import { FC, ReactNode, UIEvent, useEffect, useReducer } from 'react'
import { useParams } from 'react-router-dom'
import { ScrollableContainer } from 'styles/global/ScrollableContainer'
import { theme } from 'styles/theme'
import { throttle } from 'throttle-debounce'
import { NUMBER_OF_LIST_ITEMS_TO_FETCH } from 'utils/constants'
import { AuditCallItem } from './components/AudtiCallItem'
import { TCallStatus } from 'api/calls/types'

export const AuditDrawer: FC<AuditDrawerProps> = ({
  variant,
  openedAuditId,
  openedAuditPrimaryId,
  openedAuditCallNumber,
  openedAuditWorkNumber,
  openedAuditRemarkNumber,
  openedAuditDocNumber,
  onClose,
}) => {
  const { height = 980 } = useWindowSize()
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)

  const maxScrollableHeight = height - 66 - 53 - 100 //66-header 53-subheader 100-bottomButton

  /****************************************** remarkType *************************************************/
  const [remarkType, remarkTypeToggle] = useReducer(
    (type: AuditDrawerRemarkType, newType: AuditDrawerRemarkType) => newType,
    initialAuditDrawerRemarkType,
  )

  /****************************************** Remarks Secondary *************************************************/
  const { queryData: remarksSecondaryQueryData, onScrollWithInfiniteLoad: onScrollWithInfiniteLoadRemarksSecondary } =
    useInfiniteScroll({
      limit: NUMBER_OF_LIST_ITEMS_TO_FETCH,
      query: useGetRemarkAuditSecondaryQuery,
      arg: { projectId, remarkId: openedAuditPrimaryId || openedAuditId! },
      options: { skip: variant !== 'remarks' || !openedAuditId },
    })

  const { data: remarkSecondaryAuditItemsData, isLoading: isRemarkSecondaryAuditLoading } = remarksSecondaryQueryData
  const { data: remarkSecondaryAuditItems = [] } = remarkSecondaryAuditItemsData || {}

  /****************************************** Remarks *************************************************/
  const { queryData: remarksQueryData, onScrollWithInfiniteLoad: onScrollWithInfiniteLoadRemarks } = useInfiniteScroll({
    limit: NUMBER_OF_LIST_ITEMS_TO_FETCH,
    query: useGetRemarkAuditQuery,
    arg: { projectId, remarkId: openedAuditPrimaryId || openedAuditId! },
    options: { skip: variant !== 'remarks' || !openedAuditId },
  })

  const { data: remarkAuditItemsData, isLoading: isRemarkAuditLoading } = remarksQueryData
  const { data: remarkAuditItems = [], number: remarkAuditNumber } = remarkAuditItemsData || {}

  /****************************************** Prescriptions *************************************************/
  const { queryData: prescriptionsQueryData, onScrollWithInfiniteLoad: onScrollWithInfiniteLoadPrescriptions } =
    useInfiniteScroll({
      limit: NUMBER_OF_LIST_ITEMS_TO_FETCH,
      query: useGetPrescriptionAuditQuery,
      arg: { projectId, prescriptionId: openedAuditId! },
      options: { skip: variant !== 'prescriptions' || !openedAuditId },
    })

  const { data: prescriptionAuditItemsData, isLoading: isPrescriptionAuditLoading } = prescriptionsQueryData
  const { data: prescriptionAuditItems = [], number: prescriptionAuditNumber } = prescriptionAuditItemsData || {}

  /****************************************** Ksg *************************************************/
  const { queryData: ksgQueryData, onScrollWithInfiniteLoad: onScrollWithInfiniteLoadKsg } = useInfiniteScroll({
    limit: NUMBER_OF_LIST_ITEMS_TO_FETCH,
    query: useGetKsgAuditQuery,
    arg: { projectId, workId: openedAuditId! },
    options: { skip: variant !== 'ksg' || !openedAuditId },
  })

  const { data: ksgAuditItemsData, isLoading: isKsgAuditLoading } = ksgQueryData
  // const { data: ksgAuditItems = [], number: ksgAuditNumber } = ksgAuditItemsData || {}
  const { data: ksgAuditItems = [] } = ksgAuditItemsData || {}

  /****************************************** Call *************************************************/
  const { queryData: callQueryData, onScrollWithInfiniteLoad: onScrollWithInfiniteLoadCall } = useInfiniteScroll({
    limit: NUMBER_OF_LIST_ITEMS_TO_FETCH,
    query: useGetCallAuditQuery,
    arg: { projectId, callId: openedAuditId! },
    options: { skip: variant !== 'call' || !openedAuditId },
  })

  const { data: callAuditItemsData, isLoading: isCallAuditLoading } = callQueryData
  const { data: callAuditItems = [] } = callAuditItemsData || {}

  /****************************************** Documents *************************************************/
  const { queryData: documentQueryData, onScrollWithInfiniteLoad: onScrollWithInfiniteLoadDocuments } =
    useInfiniteScroll({
      limit: NUMBER_OF_LIST_ITEMS_TO_FETCH,
      query: useGetDocumentAuditQuery,
      arg: { projectId, docId: openedAuditId! },
      options: { skip: variant !== 'document' || !openedAuditId },
    })

  const { data: documentAuditItemsData, isLoading: isDocumentAuditLoading } = documentQueryData
  const { data: documentAuditItems = [], number: documentAuditNumber } = documentAuditItemsData || {}

  const isLoading =
    isRemarkAuditLoading ||
    isPrescriptionAuditLoading ||
    isKsgAuditLoading ||
    isDocumentAuditLoading ||
    isRemarkSecondaryAuditLoading ||
    isCallAuditLoading

  const auditItems: Record<
    AuditDrawerVariant,
    (RemarkAudit | RemarkSecondaryAudit | PrescriptionAudit | WorkAudit | DocumentAudit | CallAudit)[]
  > = {
    call: callAuditItems,
    remarks: remarkType === 'statusChanges' ? remarkAuditItems : remarkSecondaryAuditItems,
    prescriptions: prescriptionAuditItems,
    ksg: ksgAuditItems,
    document: documentAuditItems,
  }

  const onScrollWithInfiniteLoad: Record<AuditDrawerVariant, throttle<(e: UIEvent<HTMLDivElement>) => void>> = {
    remarks:
      remarkType === 'statusChanges' ? onScrollWithInfiniteLoadRemarks : onScrollWithInfiniteLoadRemarksSecondary,
    prescriptions: onScrollWithInfiniteLoadPrescriptions,
    ksg: onScrollWithInfiniteLoadKsg,
    call: onScrollWithInfiniteLoadCall,
    document: onScrollWithInfiniteLoadDocuments,
  }

  // remarkAudit props
  // const remarkAuditIconByAction: Record<RemarkAuditAction, ReactNode> = {
  //   CREATE: <CheckCircleIcon fontSize='small' className='icon-create' />,
  //   STATUS: <EditCircleIcon fontSize='small' color='primary' />,
  // }

  // const remarkAuditTitleByAction: Record<RemarkAuditAction, string> = {
  //   CREATE: 'Создано замечание',
  //   STATUS: 'Изменен статус',
  // }

  /****************************************** props prescriptionAudit *************************************************/
  const prescriptionAuditIconByAction: Record<PrescriptionAuditAction, ReactNode> = {
    CREATE: <CheckCircleIcon fontSize='small' className='icon-create' />,
    STATUS: <EditCircleIcon fontSize='small' color='primary' />,
  }

  const prescriptionAuditTitleByAction: Record<PrescriptionAuditAction, string> = {
    CREATE: 'Создано предписание',
    STATUS: 'Изменен статус',
  }

  /****************************************** props ksgAudit *************************************************/
  const ksgAuditIconByAction: Record<WorkAuditAction, ReactNode> = {
    CREATE: <CheckCircleIcon fontSize='small' className='icon-create' />,
    STATUS: <EditCircleIcon fontSize='small' color='primary' />,
  }

  const ksgAuditTitleByAction: Record<WorkAuditAction, string> = {
    CREATE: 'Создана работа',
    STATUS: 'Изменен статус',
  }

  /****************************************** props documentAudit *************************************************/
  const documentAuditIconByAction: Record<DocumentAuditAction, ReactNode> = {
    CREATE: <CheckCircleIcon fontSize='small' className='icon-create' />,
    EDIT: <EditCircleIcon fontSize='small' color='primary' />,
    FILE: (
      <IconWrapper imgColor={theme.palette.text.light || ''}>
        <AttachFileIcon fontSize='small' />
      </IconWrapper>
    ),
  }

  const documentAuditTitleByAction: Record<DocumentAuditAction, string> = {
    CREATE: 'Создан документ',
    EDIT: 'Изменены данные',
    FILE: 'Прикреплен файл',
  }

  /****************************************** props common audit *************************************************/
  const drawerSubtitleByVariant: Record<AuditDrawerVariant, string> = {
    remarks: openedAuditPrimaryId ? `Замечание № ${openedAuditRemarkNumber}` : `Замечание № ${remarkAuditNumber}`,
    prescriptions: `Предписание № ${prescriptionAuditNumber}`,
    ksg: `Работа № ${openedAuditWorkNumber || '-'}`,
    document: `Документ № ${documentAuditNumber || '-'}`,
    call: `Вызов № ${openedAuditCallNumber || '-'}`,
  }

  const defaultActions: PrescriptionAuditAction[] | WorkAuditAction[] = ['CREATE', 'STATUS']

  const documentActions: DocumentAuditAction[] = ['CREATE', 'EDIT', 'FILE']

  const isDefaultAction = (action: any): action is PrescriptionAuditAction | WorkAuditAction => {
    return defaultActions.includes(action)
  }

  const isDocumentAction = (action: any): action is DocumentAuditAction => {
    return documentActions.includes(action)
  }

  const getAuditIconByVariant = (
    action: RemarkAuditAction | PrescriptionAuditAction | WorkAuditAction | DocumentAuditAction | CallAuditAction,
  ) => {
    if (isDefaultAction(action)) {
      switch (variant) {
        case 'prescriptions':
          return prescriptionAuditIconByAction[action]
        case 'ksg':
          return ksgAuditIconByAction[action]
      }
    }

    if (isDocumentAction(action)) return documentAuditIconByAction[action]
  }

  const getAuditTitleByVariant = (
    action: RemarkAuditAction | PrescriptionAuditAction | WorkAuditAction | DocumentAuditAction | CallAuditAction,
  ) => {
    if (isDefaultAction(action)) {
      switch (variant) {
        case 'prescriptions':
          return prescriptionAuditTitleByAction[action]
        case 'ksg':
          return ksgAuditTitleByAction[action]
      }
    }

    if (isDocumentAction(action)) return documentAuditTitleByAction[action]

    return ''
  }

  const getAuditSubtitleByVariant = (
    audit: RemarkAudit | RemarkSecondaryAudit | PrescriptionAudit | WorkAudit | DocumentAudit | CallAudit,
  ) => {
    if (isDefaultAction(audit.action)) {
      switch (variant) {
        case 'remarks':
          // @ts-ignore
          return audit?.prescription ? `Предписание: № ${audit.prescription}` : ''
        case 'prescriptions':
          // @ts-ignore
          return audit?.shortcoming ? `Замечание: № ${audit.shortcoming}` : ''
        case 'ksg':
          return ''
      }
    }

    if (isDocumentAction(audit.action)) return ''

    return ''
  }

  const getAuditStatusByVariant = (
    status?: RemarkStatuses | PrescriptionStatus | WorkStatuses | callStatusesBackend | TCallStatus,
  ) => {
    switch (variant) {
      case 'call':
        return callStatusesData[status as callStatusesBackend]
      case 'remarks':
        return remarkStatusesClient[status as RemarkStatuses]
      case 'prescriptions':
        return prescriptionStatusRuByEn[status as PrescriptionStatus]
      case 'ksg':
        return workStatusesClient[(status || 'EMPTY') as WorkStatuses]
    }
  }

  /****************************************** useEffect *************************************************/
  useEffect(() => {
    if (openedAuditPrimaryId) {
      remarkTypeToggle(initialAuditDrawerRemarkTypeIsSecondary)
    } else {
      remarkTypeToggle(initialAuditDrawerRemarkType)
    }
  }, [openedAuditId, openedAuditPrimaryId])

  return (
    <Drawer anchor='left' open={!!openedAuditId} onClose={onClose}>
      <AuditDrawerWrapper pb={2.5}>
        <DrawerTopBar>
          <Typography variant='h1' color={theme.palette.primary.main}>
            История изменений
          </Typography>
        </DrawerTopBar>

        <Divider />

        <DrawerTopBar style={{ paddingTop: 16 }}>
          <Typography variant='body2' fontWeight={500} color={theme.palette.text.dark}>
            {drawerSubtitleByVariant[variant]}
          </Typography>
        </DrawerTopBar>

        <Divider />

        {variant === 'remarks' && remarkSecondaryAuditItems.length !== 0 && (
          <DrawerTopBar style={{ paddingBottom: 0 }}>
            <ToggleButtonGroup<AuditDrawerRemarkType>
              onToggleButtonChange={(e: React.SyntheticEvent, toggleValue: AuditDrawerRemarkType) => {
                remarkTypeToggle(toggleValue)
              }}
              toggleButtonData={[
                {
                  value: 'statusChanges',
                  label: 'Изменение статуса',
                  children: openedAuditPrimaryId ? (
                    <StyledTooltip
                      title={remarkSecondaryStatusChangesNotifyInfo}
                      bgColor={theme.palette.bg.white}
                      textColor={theme.palette.text.dark}
                    >
                      <InfoIcon fontSize='inherit' color='action' />
                    </StyledTooltip>
                  ) : (
                    false
                  ),
                },
                { value: 'remarksSecondary', label: 'Повторные замечания' },
              ]}
              currentValue={remarkType}
            />
          </DrawerTopBar>
        )}

        <AuditDrawerContentWrapper flex={1} justifyContent='space-between' alignItems='center'>
          <ScrollableContainer
            onScroll={onScrollWithInfiniteLoad[variant]}
            width='100%'
            // maxHeight={maxScrollableHeight}
            // bgcolor={'red'}
            divider={!['remarks', 'prescriptions', 'call'].includes(variant) && <Divider />}
          >
            {isLoading ? (
              <Progress />
            ) : (
              auditItems[variant].map((audit) => {
                switch (variant) {
                  case 'remarks':
                    return remarkType === 'statusChanges' ? (
                      <AuditRemarkItem remarkAudit={audit as RemarkAudit} />
                    ) : (
                      <AuditRemarkSecondaryItem remarkSecondaryAudit={audit as RemarkSecondaryAudit} />
                    )

                  case 'prescriptions':
                    return <AuditPrescriptionItem prescriptionAudit={audit as PrescriptionAudit} />

                  case 'call':
                    return <AuditCallItem callAudit={audit as CallAudit} />

                  default:
                    return (
                      <AuditItem
                        audit={audit}
                        auditIcon={getAuditIconByVariant(audit.action)}
                        auditTitle={getAuditTitleByVariant(audit.action)}
                        auditSubtitle={getAuditSubtitleByVariant(audit)}
                        status={'value' in audit ? getAuditStatusByVariant(audit.value) : undefined}
                      />
                    )
                }
              })
            )}
          </ScrollableContainer>

          <Box width='100%' px={2.5}>
            <Button onClick={onClose} fullWidth size='small'>
              Закрыть
            </Button>
          </Box>
        </AuditDrawerContentWrapper>
      </AuditDrawerWrapper>
    </Drawer>
  )
}
