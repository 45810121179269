import { ColDef } from 'ag-grid-community'
import { PrescriptionSenderCompany, PrescriptionSenderUser } from 'api/prescriptions/types'
import { UserProfile } from 'api/profile/types'
import { TInspectionStatus } from 'core/types/call'
import { Media } from 'core/types/global'
import {
  auditColumn,
  authorColumn,
  createdDateColumn,
  descriptionColumn,
  expectedDateColumn,
  inspectionFactDateColumn,
  inspectionPhoneColumn,
  inspectionPlanDateColumn,
  inspectionResponsibleColumn,
  inspectionStatusColumn,
  numberColumn,
  phoneColumn,
  responsibleCompanyColumn,
  responsibleUserColumn,
  statusColumn,
  typeColumn,
  workDoneByColumn,
} from 'pages/Calls/components/CallTable/CallTable.types'

export interface ICallShort {
  status: TCallStatus
  // assignmentType: ICallAssignmentType
  responsibleCompany: ICallResponsibleCompany
  responsibleUser: ICallResponsibleUser
  phone: string
  type: TCallType
  workDoneBy: string
  description: string
  expectedDate: string
}

export interface ICallFull extends Omit<ICallShort, 'responsibleCompany' | 'responsibleUser' | 'assignmentType'> {
  id: number
  number: string
  responsibleCompany: PrescriptionSenderCompany
  responsibleUser: PrescriptionSenderUser
  // assignmentType: ICallFullAssignmentType
  attachments: Media[]
  createdDate: string
  author: UserProfile
  deviation: number | null
  inspection: IInspection
}

export type TCallStatus = 'CREATED' | 'SENT' | 'CANCELLED' | 'COMPLETE'
export type TCallStatusRu = 'Создан' | 'Направлен' | 'Отменен' | 'Завершен'

export interface ICallAssignmentType {
  assignmentTypeId: number | null
  custom: string | null
}

export interface ICallResponsibleCompany {
  company: string | null
  projectMember: number | null
}

export interface ICallResponsibleUser {
  engineerQC: number | null
  representative: number | null
}

export type TCallType = 'OSR' | 'OVR' | 'OOK'

export interface ICallFullAssignmentType {
  id: number | null
  description: string | null
  note: string | null
  title: string
}

export const callTypeRuByEn: Record<TCallType, string> = {
  OSR: 'Освидетельствование скрытых работ',
  OVR: 'Освидетельствование выполненных работ',
  OOK: 'Освидетельствование ответственных конструкций',
}

export interface IInspectionShort {
  description: string | null
  factDate: string | null
  phone: string | null
  planDate: string | null
  planTime: string | null
  responsible: ICallResponsibleUser
  status: TInspectionStatus
}

export interface IInspection {
  status: TInspectionStatus | null
  responsible: IInspectionResponsible | null
  phone: string | null
  planDate: string | null
  planTime: string | null
  factDate: string | null
  description: string | null
  repeated: boolean
}

export interface IInspectionResponsible extends PrescriptionSenderUser {
  company: string | null
}

// COLUMNS
export type TCallTableColumn =
  | 'NUMBER'
  | 'STATUS'
  | 'TYPE'
  | 'WORK_DONE_BY'
  | 'DESCRIPTION'
  | 'CREATED_DATE'
  | 'EXPECTED_DATE'
  | 'AUTHOR'
  | 'RESPONSIBLE_COMPANY'
  | 'RESPONSIBLE_USER'
  | 'PHONE'
  | 'INSPECTION_STATUS'
  | 'INSPECTION_PLAN_DATE'
  | 'INSPECTION_FACT_DATE'
  | 'INSPECTION_RESPONSIBLE'
  | 'INSPECTION_PHONE'
  | 'AUDIT'

export const callTableColumns: TCallTableColumn[] = [
  'NUMBER',
  'STATUS',
  'TYPE',
  'WORK_DONE_BY',
  'DESCRIPTION',
  'CREATED_DATE',
  'EXPECTED_DATE',
  'AUTHOR',
  'RESPONSIBLE_COMPANY',
  'RESPONSIBLE_USER',
  'PHONE',
  'INSPECTION_STATUS',
  'INSPECTION_PLAN_DATE',
  'INSPECTION_FACT_DATE',
  'INSPECTION_RESPONSIBLE',
  'INSPECTION_PHONE',
  'AUDIT',
]

export const callTableColumnByValue: Record<TCallTableColumn, ColDef> = {
  NUMBER: numberColumn,
  STATUS: statusColumn,
  TYPE: typeColumn,
  WORK_DONE_BY: workDoneByColumn,
  DESCRIPTION: descriptionColumn,
  CREATED_DATE: createdDateColumn,
  EXPECTED_DATE: expectedDateColumn,
  AUTHOR: authorColumn,
  RESPONSIBLE_COMPANY: responsibleCompanyColumn,
  RESPONSIBLE_USER: responsibleUserColumn,
  PHONE: phoneColumn,
  INSPECTION_STATUS: inspectionStatusColumn,
  INSPECTION_PLAN_DATE: inspectionPlanDateColumn,
  INSPECTION_FACT_DATE: inspectionFactDateColumn,
  INSPECTION_RESPONSIBLE: inspectionResponsibleColumn,
  INSPECTION_PHONE: inspectionPhoneColumn,
  AUDIT: auditColumn,
}

export interface ICallColumnSetting {
  name: TCallTableColumn
  size: number | null
}
