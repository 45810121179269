import { FC } from 'react'
import { Wrapper } from './Inspection.styles'
import { Button, Stack, Typography } from '@mui/material'
import { theme } from 'styles/theme'
import { Tooltip } from 'components/Tooltip'
import {
  InfoOutlined as InfoIcon,
  Person as PersonIcon,
  Smartphone as PhoneIcon,
  EventNote as CalendarIconPlan,
  EventAvailable as CalendarIconFact,
  Chat as ChatIcon,
} from '@mui/icons-material'
import { inspectionTooltipText } from './Inspection.text'
import { FieldItem } from 'pages/Remarks/components/RemarkForm/components/FieldItem'
import { getInspectionStatusData, IInspectionProps } from './Inspection.types'
import { EliminationStatusMarker as StatusMarker } from 'pages/Remarks/components/EliminationDrawer/components/EliminationForm/EliminationForm.styles'
import { formatMaskPhone } from 'components/PhoneFieldForm/PhoneFieldForm.utils'
import { parseResponseDate } from 'utils/dates/parseResponseDate'
import { connectNames } from 'utils/connectNames'
import { parseResponseTime } from 'utils/dates/parseResponseTime'
import { useTypedSelector } from 'store/store'
import { isUserHighAccessSelector } from 'store/slices/profile'

export const Inspection: FC<IInspectionProps> = ({ callData, readOnly, onDrawerOpen }) => {
  const { status: callStatus, inspection } = callData || {}
  const { status: inspectionStatus, phone, planDate, planTime, factDate, description, responsible } = inspection || {}
  const isUserHighAccess = useTypedSelector(isUserHighAccessSelector)
  const isEditButtonShown: boolean =
    isUserHighAccess && callStatus === 'SENT' && (inspectionStatus === 'PLANNING' || inspectionStatus === 'PLANNED')
  const isReinspectionButtonShown = isUserHighAccess && callStatus === 'SENT' && inspectionStatus === 'REJECTED'

  const parsedPlanDate = planDate ? parseResponseDate(planDate).fullDate : '—'
  const parsedPlanTime = parseResponseTime(planTime).timeShort
  const parsedFactDate = factDate ? parseResponseDate(factDate).fullDate : '—'

  const inspectionStatusData = getInspectionStatusData(inspectionStatus)

  const onInspectionDrawerOpen = () => {
    onDrawerOpen('inspection')
  }

  const onWindowOpen = () => {
    onDrawerOpen('reinspection')
  }

  return (
    <Wrapper spacing={2.5}>
      <Stack spacing={2.5}>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <Tooltip title={inspectionTooltipText} bgColor={theme.palette.bg.white} textColor={theme.palette.text.dark}>
              <InfoIcon fontSize='inherit' color='action' />
            </Tooltip>
            <Typography variant='subtitle1' textAlign={'start'} color={theme.palette.text.dark}>
              Осмотр
            </Typography>
          </Stack>

          {isReinspectionButtonShown && (
            <Button
              onClick={onWindowOpen}
              variant='outlined'
              disabled={readOnly}
              color='secondary'
              style={{ padding: '3px 10px', height: 'auto', border: 'none' }}
            >
              Запросить повторный осмотр
            </Button>
          )}

          {isEditButtonShown && (
            <Button
              onClick={onInspectionDrawerOpen}
              variant='outlined'
              color='secondary'
              style={{ padding: '3px 10px', height: 'auto', border: 'none' }}
            >
              Редактировать
            </Button>
          )}
        </Stack>

        <FieldItem title='Статус осмотра'>
          <Stack direction='row' alignItems='center'>
            <StatusMarker markerColor={inspectionStatusData.color} />
            <Typography variant='body2' color={theme.palette.text.dark}>
              {inspectionStatusData.body}
            </Typography>
          </Stack>
        </FieldItem>

        <Stack spacing={1.5}>
          <FieldItem
            title='Ответственный за осмотр'
            icon={<PersonIcon fontSize={'medium'} color={'secondary'} />}
            alignItems='flex-start'
          >
            <Stack alignItems='flex-end'>
              <Typography variant='body2' color={theme.palette.text.dark} textAlign='end'>
                {responsible?.user ? connectNames(responsible?.user) : responsible?.representative?.fullName || '—'}
              </Typography>
              <Typography variant='body2' color={theme.palette.text.disabled} textAlign='end'>
                {responsible?.company}
              </Typography>
            </Stack>
          </FieldItem>

          <FieldItem title='Телефон ответственного' icon={<PhoneIcon fontSize={'medium'} color={'secondary'} />}>
            <Typography variant='body2' color={theme.palette.text.dark}>
              {formatMaskPhone(phone) || '—'}
            </Typography>
          </FieldItem>
        </Stack>

        <Stack spacing={1.5}>
          <FieldItem
            title='Планируемая дата осмотра'
            icon={<CalendarIconPlan fontSize={'medium'} color={'secondary'} />}
          >
            <Stack direction='row' spacing={1.25}>
              <Typography variant='body2' color={theme.palette.text.dark}>
                {parsedPlanDate}
              </Typography>

              {parsedPlanTime && (
                <Typography variant='body2' color={theme.palette.text.secondary}>
                  {parsedPlanTime}
                </Typography>
              )}
            </Stack>
          </FieldItem>
          <FieldItem
            title='Фактическая дата осмотра'
            icon={<CalendarIconFact fontSize={'medium'} color={'secondary'} />}
          >
            <Typography variant='body2' color={theme.palette.text.dark}>
              {parsedFactDate}
            </Typography>
          </FieldItem>
        </Stack>

        {description && (
          <FieldItem
            title={inspectionStatus === 'COMPLETED' ? 'Результат осмотра' : 'Обоснование'}
            icon={<ChatIcon fontSize={'medium'} color={'secondary'} />}
            direction='column'
          >
            <Typography variant='body2' color={theme.palette.text.dark} textAlign='start'>
              {description}
            </Typography>
          </FieldItem>
        )}
      </Stack>
    </Wrapper>
  )
}
