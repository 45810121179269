import { FC, useCallback, useState } from 'react'
import { IInspectionDrawerProps } from './InspectionDrawer.types'
import { Drawer, Stack, Typography } from '@mui/material'
import { DrawerTopBar } from 'pages/Administration/References/ReferenceContent/ReferenceDrawers/EditAndAddDrawer/EditAndAddDrawer.styles'
import { theme } from 'styles/theme'
import { InspectionForm } from './components/InspectionForm'
import { Divider } from 'components/Divider'

export const InspectionDrawer: FC<IInspectionDrawerProps> = ({ callData, isOpen, onClose }) => {
  const [dirty, setDirty] = useState<boolean>(false)

  const onFormChange = useCallback((dirty: boolean) => {
    setDirty(dirty)
  }, [])

  const onDrawerClose = (immediately?: boolean) => {
    onClose('closeDrawer', dirty, immediately)
  }

  return (
    <Drawer anchor='right' open={isOpen} onClose={() => onDrawerClose()}>
      <Stack width={'500px'} height={'100%'}>
        <DrawerTopBar>
          <Typography variant='h1' color={theme.palette.primary.main}>
            Редактирование осмотра
          </Typography>
        </DrawerTopBar>

        <Divider />

        <InspectionForm callData={callData} onFormChange={onFormChange} onClose={onDrawerClose} />
      </Stack>
    </Drawer>
  )
}
