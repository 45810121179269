import { FC, useState, MouseEvent, useMemo, useCallback } from 'react'
import { ICallStatusCellProps, TCallTableDialogTrigger } from './CallStatusCell.types'
import { StatusCellContent, StatusCellMenuItemProps } from 'pages/Remarks/components/RemarksTable/cells/StatusCell'
import { ColoredTitle } from 'components/ColoredTitle'
import { callStatusColorByEn, callStatuses, callStatusRuByEn, getCallStatusesForMenu } from 'core/types/call'
import { TCallStatus } from 'api/calls/types'
import { KeyboardArrowDown as ArrowIcon } from '@mui/icons-material'
import { Divider, IconButton, Menu, Stack, Typography } from '@mui/material'
import { StyledSelectMenuItem } from 'components/UserManagement/components/UserRoleSelection'
import { useEditCallStatusMutation, useLazyCheckCallForEmailNotificationInTableQuery } from 'api/calls'
import { useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { useTypedSelector } from 'store/store'
import { profileSelector } from 'store/slices/profile'
import { ModuleRegistry } from 'ag-grid-community'
import { RowApiModule } from 'ag-grid-community'
import { Repeat as RepeatIcon, Error as ErrorIcon } from '@mui/icons-material'
import { theme } from 'styles/theme'
import { useConfirmDialog, UseExitConfirmProps } from 'hooks/useConfirmDialog'

ModuleRegistry.registerModules([RowApiModule])

export const CallStatusCell: FC<ICallStatusCellProps> = (props) => {
  const { value, data, api } = props
  const { role, company } = useTypedSelector(profileSelector)
  const isContractor: boolean = role === 'contractor'
  const access: boolean =
    role === 'admin' ||
    (role === 'engineer_qc' && company.userCompanyName === data?.author.company.userCompanyName) ||
    (isContractor && data?.status !== 'COMPLETE')
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const status = value as TCallStatus
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null)
  const [editCallStatus, { ...editCallStatusResponse }] = useEditCallStatusMutation()
  const { enqueueSnackbar } = useSnackbar()
  const shouldReinspection =
    data?.status === 'SENT' && data.inspection.status === 'PLANNING' && data?.inspection.repeated
  const inspectionRejected = data?.inspection.status === 'REJECTED'
  const [
    checkCallForEmailNotification,
    { isLoading: isNotificationChecking, ...checkCallForEmailNotificationResponse },
  ] = useLazyCheckCallForEmailNotificationInTableQuery()
  const [tempStatus, setTempStatus] = useState<TCallStatus>(value)

  const onStatusCellClick = (e: MouseEvent<HTMLDivElement>) => {
    setMenuAnchor(e.currentTarget)
  }

  const onMenuClose = () => {
    setMenuAnchor(null)
  }

  const onMenuClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
  }

  const statusCellMenuItemsData: StatusCellMenuItemProps<TCallStatus>[] = getCallStatusesForMenu(
    value,
    isContractor,
  ).map((menuStatus) => {
    const valueForOnClick = menuStatus
    const checked = status === valueForOnClick

    return {
      value: menuStatus,
      valueForOnClick,
      checked: checked,
      key: menuStatus,
      children: callStatusRuByEn[menuStatus],
    }
  })

  const menuItemClickHandler = (status: TCallStatus) => () => {
    setMenuAnchor(null)

    setTempStatus(status)
    checkCallForEmailNotification({ projectId, callId: data!.id, body: { status } })
  }

  const onEditCallStatus = (notification: boolean) => {
    editCallStatus({ projectId, callId: data!.id, notification, status: tempStatus })
  }

  const handleCallNotification = (confirm: boolean) => {
    onEditCallStatus(confirm)
  }

  const [confirmDialogTrigger, setConfirmDialogTrigger] = useState<TCallTableDialogTrigger>('callNotification')

  const dataForConfirmDialog: Record<NonNullable<typeof confirmDialogTrigger>, UseExitConfirmProps> = {
    callNotification: {
      handleConfirm: handleCallNotification,
      title: 'Отправить уведомление?',
      body: 'Уведомление о смене статуса будет направлено на e-mail представителей участников вызова.',
      denyButtonText: 'Нет',
    },
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog[confirmDialogTrigger])

  useMutationHandlers(checkCallForEmailNotificationResponse, (responseData) => {
    if (responseData.data) {
      setConfirmDialogTrigger('callNotification')
      openConfirm()
    } else onEditCallStatus(false)
  })

  useMutationHandlers(editCallStatusResponse, (data) => {
    if (!data.success) {
      enqueueSnackbar(data.description, { variant: 'error' })
      return
    }

    api.applyTransaction({ update: [data.data] })

    enqueueSnackbar('Статус успешно изменён.', { variant: 'success' })
  })

  const statusIcon = useMemo(() => {
    if (shouldReinspection) return <RepeatIcon fontSize='small' sx={{ fill: theme.palette.text.light }} />

    if (inspectionRejected) return <ErrorIcon fontSize='small' sx={{ fill: theme.palette.text.light }} />
  }, [shouldReinspection, inspectionRejected])

  const statusTooltipTitle = useMemo(() => {
    if (shouldReinspection) return 'Запрошено повторное рассмотрение заявки на проведение осмотра'

    if (inspectionRejected)
      return (
        <Stack spacing={0.5}>
          <Typography variant='subtitle2' fontSize={12} color={theme.palette.text.dark}>
            Запрос на проведение осмотра был отклонен
          </Typography>

          <Divider />

          <Typography variant='subtitle2' fontSize={12} color={theme.palette.text.dark}>
            Обоснование:
          </Typography>
          <Typography variant='body2'>{data?.inspection?.description}</Typography>
        </Stack>
      )

    return null
  }, [shouldReinspection, inspectionRejected, data])

  return (
    <>
      <StatusCellContent onClick={onStatusCellClick} pl={access ? 2.75 : 0} height={'100%'} alignItems={'center'}>
        <ColoredTitle
          body={callStatusRuByEn[status]}
          color={callStatusColorByEn[status]}
          icon={statusIcon}
          style={{ minWidth: '108px' }}
          tooltipTitle={statusTooltipTitle}
        />

        {access && (
          <IconButton>
            <ArrowIcon fontSize='small' />
          </IconButton>
        )}
      </StatusCellContent>
      {access && (
        <Menu anchorEl={menuAnchor} open={!!menuAnchor} onClick={onMenuClick} onClose={onMenuClose}>
          {(statusCellMenuItemsData || []).map(({ value, valueForOnClick, children, checked, key }) => {
            if (value) {
              return (
                <StyledSelectMenuItem
                  onClick={menuItemClickHandler(valueForOnClick)}
                  style={{ width: menuAnchor?.clientWidth, maxWidth: '100%' }}
                  value={value}
                  checked={checked}
                  key={key}
                >
                  {children}
                </StyledSelectMenuItem>
              )
            }
          })}
        </Menu>
      )}

      <ConfirmDialog />
    </>
  )
}
