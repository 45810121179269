import {
  authorColumn,
  commentColumn,
  contractorColumn,
  dateDoneColumn,
  dateEndColumn,
  dateStartColumn,
  executionControlColumn,
  inspectionTypeColumn,
  numberColumn,
  receiverColumn,
  senderCompanyColumn,
  senderUserColumn,
  typeColumn,
} from '../../pages/Prescriptions/components/PrescriptionsTable'
import { CustomColumnDef } from '../../pages/Remarks/components/RemarksTable'
import { PublicUserProfile, UserProfile } from 'api/profile/types'
import { ContractorRespUser, ResponsibleRepresentative, ShortUserInfo } from 'api/projects/types'
import { ProjectMember } from 'api/references/projectMembers/types'
import { Representative } from 'api/references/representatives/types'
import { RemarkAuditUser, RemarkFull } from 'api/remarks/types'
import { AuditMails, FullPeriod, Media } from 'core/types/global'
import { PrescriptionInspection, PrescriptionStatus, PrescriptionType } from 'core/types/prescription'

export interface Prescription {
  id: number
  author: UserProfile
  status: PrescriptionStatus
  number: string
  period: FullPeriod
  inspectionType: PrescriptionInspection
  type: PrescriptionType
  comment: string
  executionControl: ResponsibleRepresentative
  receiver: ResponsibleRepresentative
  contractor: ContractorRespUser
  senderCompany: PrescriptionSenderCompany
  senderUser: PrescriptionSenderUser
  attachments: Media[]
  preloadedShortcomings: RemarkFull[]
  canEditStatus: boolean
}

export interface PrescriptionAudit {
  action: PrescriptionAuditAction
  shortcoming: string | null
  previous: PrescriptionStatus
  value: PrescriptionStatus
  timestamp: string
  user: RemarkAuditUser
  mailRecipients: AuditMails[] | null
}

export interface PrescriptionSenderCompany {
  company: string | null
  projectMember: ProjectMember | null
}

export interface PrescriptionSenderUser {
  user: ShortUserInfoWithPosition | null
  representative: Representative | null
}

export type PrescriptionAuditAction = 'CREATE' | 'STATUS'

export interface ShortUserInfoWithPosition extends ShortUserInfo {
  position: string
  email: string
}

// BIND
export interface RequestBindChoice {
  all: boolean
  includedObjects: BindObjectInfo
  excludedObjects: BindObjectIds
}

export interface BindObjectInfo extends Record<string, SelectionData> {}

export type BindObjectIds = number[]

export interface SelectionData {
  all: boolean
  include: number[]
  exclude: number[]
}

export interface BindObject {
  id: number
  title: string
  total: number
  chosen: boolean
  chosenShortcomings: number
  shortcomings: BindRemark[]
}

export interface BindRemark {
  chosen: boolean
  shortcoming: RemarkFull
}

export interface PrescriptionAtomic {
  addShortcomings: string
}

// FILTER
export interface PrescriptionFilterItem {
  id: number | string
  text: string
  subtext: string
  data?: {
    chosen: boolean
  }
}

// COLUMNS
export type PrescriptionColumn =
  | 'NUMBER'
  | 'STATUS'
  | 'TYPE'
  | 'DATE_START'
  | 'AUTHOR'
  | 'DATE_DONE'
  | 'CONTRACTOR'
  | 'INSPECTION_TYPE'
  | 'COMMENT'
  | 'SENDER_COMPANY'
  | 'SENDER_USER'
  | 'RECEIVER'
  | 'EXECUTION_CONTROL'
  | 'DATE_END'

export const prescriptionDefaultColumns: PrescriptionColumn[] = [
  'NUMBER',
  'STATUS',
  'TYPE',
  'DATE_START',
  'AUTHOR',
  'DATE_DONE',
  'SENDER_COMPANY',
  'CONTRACTOR',
]

export const prescriptionColumns: PrescriptionColumn[] = [
  'NUMBER',
  'STATUS',
  'TYPE',
  'DATE_START',
  'AUTHOR',
  'DATE_DONE',
  'CONTRACTOR',
  'INSPECTION_TYPE',
  'COMMENT',
  'SENDER_COMPANY',
  'SENDER_USER',
  'RECEIVER',
  'EXECUTION_CONTROL',
  'DATE_END',
]

export const prescriptionColumnNameByValue: Record<PrescriptionColumn, string> = {
  NUMBER: 'Номер предписания',
  STATUS: 'Статус',
  TYPE: 'Тип предписания',
  DATE_START: 'Дата предписания',
  AUTHOR: 'Автор',
  DATE_DONE: 'Фактическая дата устранения',
  CONTRACTOR: 'Организация, получившая предписание',
  INSPECTION_TYPE: 'Вид обследования',
  COMMENT: 'Комментарий',
  SENDER_COMPANY: 'Организация, выдавшая предписание',
  SENDER_USER: 'Предписание выдал',
  RECEIVER: 'Предписание получил',
  EXECUTION_CONTROL: 'Контроль за устранением нарушений',
  DATE_END: 'Установленная дата устранения',
}

export const prescriptionColumnByValue: Record<Exclude<PrescriptionColumn, 'STATUS'>, CustomColumnDef<Prescription>> = {
  NUMBER: numberColumn,
  TYPE: typeColumn,
  INSPECTION_TYPE: inspectionTypeColumn,
  DATE_START: dateStartColumn,
  DATE_END: dateEndColumn,
  DATE_DONE: dateDoneColumn,
  COMMENT: commentColumn,
  AUTHOR: authorColumn,
  CONTRACTOR: contractorColumn,
  SENDER_COMPANY: senderCompanyColumn,
  SENDER_USER: senderUserColumn,
  RECEIVER: receiverColumn,
  EXECUTION_CONTROL: executionControlColumn,
}

export interface EliminationResultShort {
  date: string
  number: string | null
  comment: string | null
  shortcomings: SelectionData | null
}

export interface EliminationResultFull {
  id: number
  author: EliminationResultProfile
  comment: string | null
  created: string
  date: string
  link: string
  number: string
  shortcomings: EliminationResultFullRemarks
  attachments: Pick<Media, 'id' | 'key' | 'link'>[]
}

export interface EliminationResultFullRemarks {
  list: EliminationResultSelectableRemark[]
  total: number
}

export interface EliminationResultProfile
  extends Pick<
    PublicUserProfile,
    'avatar' | 'id' | 'email' | 'firstName' | 'lastName' | 'middleName' | 'phone' | 'role'
  > {
  position: string
}

export interface EliminationResultSelectableRemark
  extends Pick<RemarkFull, 'id' | 'status' | 'contractorStatus' | 'period' | 'number' | 'description'> {
  completionActs: RemarkCompletionAct[]
}

export interface RemarkCompletionAct extends Pick<EliminationResultFull, 'id' | 'date' | 'number'> {}
