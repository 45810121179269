import { InfoOutlined as InfoIcon } from '@mui/icons-material'
import { Dialog, Stack, Typography } from '@mui/material'
import { Button } from 'components/Button'
import { FieldForm, StyledCheckbox } from 'components/FieldForm'
import { Tooltip } from 'components/Tooltip'
import { FC, useEffect, useState } from 'react'
import { theme } from 'styles/theme'
import { Form, FormikProvider } from 'formik'
import { useForm } from 'hooks/useForm'
import { IReinspectionWindowProps } from './ReinspectionWindow.types'
import { StyledConfirmWindow } from './ReinspectionWindow.styles'
import { useTypedSelector } from 'store/store'
import { profileSelector } from 'store/slices/profile'
import { useRepeatInspectionMutation } from 'api/calls'
import { useParams } from 'react-router-dom'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { useSnackbar } from 'notistack'

export const ReinspectionWindow: FC<IReinspectionWindowProps> = ({ isOpen, callData, onClose }) => {
  const profile = useTypedSelector(profileSelector)
  const { id: profileId } = profile || {}
  const { projectId: projectIdString, callId: callIdString } = useParams()
  const projectId = Number(projectIdString)
  const callId = Number(callIdString)
  const { enqueueSnackbar } = useSnackbar()

  const [repeatInspection, { isLoading, ...repeatInspectionResponse }] = useRepeatInspectionMutation()

  const notificationShown: boolean =
    (callData?.inspection.responsible?.user?.id !== profileId && !!callData?.inspection.responsible?.user?.email) ||
    (callData?.inspection.responsible?.representative?.id !== profileId &&
      !!callData?.inspection.responsible?.representative?.email)

  const onSubmit = (values: any) => {
    repeatInspection({
      projectId,
      callId,
      notification: values.notification,
      body: { description: values.description },
    })
  }

  const { formik } = useForm({
    enableReinitialize: true,
    initialValues: { description: '', notification: false },
    onSubmit,
  })

  const { values, resetForm, setFieldValue } = formik
  const customDirty = !!values.description

  useEffect(() => {
    if (isOpen) return

    resetForm()
  }, [isOpen])

  useMutationHandlers(repeatInspectionResponse, (data) => {
    if (!data.success) {
      enqueueSnackbar(data.description, { variant: 'error' })
      return
    }

    onClose(false)
    enqueueSnackbar('Вызов направлен на повторное рассмотрение.', { variant: 'success' })
  })

  return (
    <Dialog open={isOpen} onClose={() => onClose(customDirty)}>
      <StyledConfirmWindow>
        <Typography variant='subtitle1' color={theme.palette.primary.main}>
          Повторно запросить осмотр?
        </Typography>

        <Typography variant='body2' color={theme.palette.text.dark}>
          Статус осмотра будет изменен на «К планированию».
        </Typography>

        <FormikProvider value={formik}>
          <Stack component={Form} spacing={'14px'}>
            <Stack spacing={1}>
              <FieldForm
                name='description'
                version='project'
                placeholder='Укажите обоснование'
                multiline
                helperText=''
                inputProps={{
                  style: {
                    height: '89px',
                    overflow: 'auto',
                  },
                }}
              />

              {notificationShown && (
                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                  <Stack direction='row' alignItems='center'>
                    <StyledCheckbox
                      id='notification'
                      onChange={(e) => setFieldValue('notification', e.target.checked)}
                    />
                    <label htmlFor='notification' style={{ cursor: 'pointer' }}>
                      <Typography variant='body2' color={theme.palette.text.dark} marginRight={1}>
                        Отправить уведомление
                      </Typography>
                    </label>
                  </Stack>

                  <Tooltip
                    title={'Уведомление о смене статуса будет направлено на e-mail ответственного за осмотр.'}
                    bgColor={theme.palette.bg.white}
                    textColor={theme.palette.text.dark}
                  >
                    <InfoIcon fontSize='inherit' color='action' />
                  </Tooltip>
                </Stack>
              )}
            </Stack>

            <Stack direction='row' spacing={1}>
              <Button type='submit' disabled={!customDirty} size='medium' color='success' fullWidth loading={isLoading}>
                Запросить
              </Button>
              <Button size='medium' fullWidth onClick={() => onClose(customDirty)}>
                Закрыть
              </Button>
            </Stack>
          </Stack>
        </FormikProvider>
      </StyledConfirmWindow>
    </Dialog>
  )
}
