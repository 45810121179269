import { FC } from 'react'
import { AuditCallItemProps } from './AudtiCallItem.types'
import { CallAuditAction } from 'api/calls/calls.types'
import { AuditInnerInfo } from '../NewAuditItem/components/AuditInfoItem/AuditInfoItem.types'
import { Person as PersonIcon, Info as InfoIcon, InfoOutlined as InfoOutlinedIcon } from '@mui/icons-material'
import { connectNames } from 'utils/connectNames'
import { userRolesEnToRu } from 'core/types/user'
import { AuditStatusGraduation } from '../NewAuditItem/components/AuditStatusGraduation'
import { callStatusRuByEn, inspectionStatusRuByEn } from 'core/types/call'
import { NewAuditItem } from '../NewAuditItem'
import { theme } from 'styles/theme'
import { Stack, Typography } from '@mui/material'

export const AuditCallItem: FC<AuditCallItemProps> = ({ callAudit }) => {
  const {
    action,
    timestamp,
    user: userData,
    value: currentCallStatus,
    previous: previousCallStatus,
    inspectionStatus,
    inspectionResponsible,
    mailRecipients,
  } = callAudit || {}
  const { role, user: user } = userData

  const {
    current: currentInspectionStatus = null,
    previous: previousInspectionStatus = null,
    description,
  } = inspectionStatus || {}

  const { current: currentInspectionResponsible, previous: previousInspectionResponsible } = inspectionResponsible || {}

  const callAuditTitleByAction: Record<CallAuditAction, string> = {
    CREATE: 'Создан вызов',
    STATUS: 'Изменен статус вызова',
    INSPECTION_STATUS: 'Изменен статус осмотра',
    INSPECTION_RESPONSIBLE: 'Установлен ответственный за осмотр',
    INSPECTION_REPEAT: 'Повторный запрос на осмотр',
  }

  const descriptionLabel = currentInspectionStatus === 'COMPLETED' ? 'Результат осмотра:' : 'Обоснование:'
  const userText = `${connectNames(user, true)}, ${userRolesEnToRu[role]}`

  const auditInfoData: AuditInnerInfo[] = [
    { icon: <PersonIcon fontSize={'medium'} color={'secondary'} />, text: 'Автор', value: userText },
    {
      icon: <InfoIcon fontSize={'medium'} color={'secondary'} />,
      text: 'Статус вызова',
      value: (
        <AuditStatusGraduation
          previous={previousCallStatus && callStatusRuByEn[previousCallStatus]}
          current={callStatusRuByEn[currentCallStatus]}
        />
      ),
    },
    {
      icon: <InfoOutlinedIcon fontSize={'medium'} color={'secondary'} />,
      text: 'Статус осмотра',
      value: (
        <AuditStatusGraduation
          previous={previousInspectionStatus && inspectionStatusRuByEn[previousInspectionStatus]}
          current={currentInspectionStatus ? inspectionStatusRuByEn[currentInspectionStatus] : 'Не установлен'}
        />
      ),
    },
    {
      icon: <PersonIcon fontSize={'medium'} color={'secondary'} />,
      text: 'Ответственный за осмотр',
      value: (
        <AuditStatusGraduation
          previous={
            previousInspectionResponsible ? (
              <Stack>
                <Typography variant='body1' fontSize={14} color={theme.palette.text.dark}>
                  {previousInspectionResponsible.name}
                </Typography>
                <Typography variant='body1' fontSize={14} color={theme.palette.secondary.gray}>
                  {previousInspectionResponsible.company}
                </Typography>
              </Stack>
            ) : (
              ''
            )
          }
          current={
            currentInspectionResponsible ? (
              <Stack>
                <Typography variant='body1' fontSize={14} color={theme.palette.text.dark}>
                  {currentInspectionResponsible.name}
                </Typography>
                <Typography variant='body1' fontSize={14} color={theme.palette.secondary.gray}>
                  {currentInspectionResponsible.company}
                </Typography>
              </Stack>
            ) : (
              ''
            )
          }
        />
      ),
      hidden: !currentInspectionResponsible,
    },
  ]

  return (
    <NewAuditItem
      auditData={{
        title: callAuditTitleByAction[action],
        timestamp,
        info: auditInfoData,
        mailRecipients,
      }}
    >
      <>
        {description && (
          <Stack p={1}>
            <Typography variant='body1' fontSize={14} color={theme.palette.secondary.gray}>
              {descriptionLabel}
            </Typography>
            <Typography variant='body1' fontSize={14} color={theme.palette.text.dark}>
              {description}
            </Typography>
          </Stack>
        )}
      </>
    </NewAuditItem>
  )
}
